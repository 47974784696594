import React from "react";
import logoUZonedarkUzone from "@/assets/images/logoUZonedarkUzone.svg";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function OrderImage(props) {
  const {
    imageWithId,
    productId,
    enableProductClick,
    imgPath,
    imageId,
    setImageId,
    scroll,
    isObjectFitContain = false,
  } = props;
  const navigate = useNavigate();
  const moveToProductDetails = (e) => {
    if (enableProductClick !== false) {
      navigate("/product/" + productId);
      e.stopPropagation();
    }
  };

  //配列にある画像のidとimageIdが一致する要素を返す（<img/>の画像選定）
  const findImage = () => imageWithId?.find((image) => image.id === imageId)?.src;
  const hasImage = !!findImage();

  const imageSrc = scroll
    ? hasImage
      ? findImage()
      : logoUZonedarkUzone
    : imgPath || logoUZonedarkUzone;

  const imageStyle = {
    width: "100%",
    aspectRatio: "4/3",
    objectFit: isObjectFitContain ? "contain" : "cover",
    pointerEvents: "none",
    borderRadius: "8px",
  };

  const defaultStyle = {
    padding: "22.5%",
    background: "rgba(31, 31, 31, 1)",
    width: "100%",
    aspectRatio: "4/3",
    objectFit: "contain",
    borderRadius: "8px",
  };

  const appliedStyle = (scroll && hasImage) || imgPath ? imageStyle : defaultStyle;

  const handleLeftScroll = () => {
    setImageId(imageId - 1);
  };
  const handleRightScroll = () => {
    setImageId(imageId + 1);
  };
  return (
    <React.Fragment>
      <Box
        style={{
          position: "relative",
        }}
      >
        {/* TODO:画像表示部をコンポーネントとして切り出して、商品詳細画面を除く全ての商品画像表示部で共用できるようにする */}
        <img
          data-cy="expandedImage"
          alt="product"
          src={imageSrc}
          style={appliedStyle}
          // 画像がエラーで読み込めなかった場合に代替画像を表示
          onError={(e) => {
            e.currentTarget.src = logoUZonedarkUzone;
            Object.assign(e.currentTarget.style, defaultStyle);
          }}
          onClick={(e) => {
            moveToProductDetails(e);
          }}
          className={`d-block cursor`}
        />

        {scroll === true && (
          <>
            <IconButton
              data-cy="leftIcon"
              component="a"
              disabled={imageId < 1}
              onClick={handleLeftScroll}
              sx={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                left: "5%",
                color: "white",
                backgroundColor: " rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  color: "rgba(255, 255, 255, 0.5)",
                },
              }}
            >
              <ArrowBackIosNewIcon />
            </IconButton>

            <IconButton
              data-cy="rightIcon"
              component="a"
              disabled={imageId >= imageWithId?.length - 1}
              onClick={handleRightScroll}
              sx={{
                position: "absolute",
                zIndex: 10,
                top: "50%",
                right: "5%",
                color: "white",
                backgroundColor: " rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  color: "rgba(255, 255, 255, 0.5)",
                },
              }}
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </>
        )}
      </Box>
    </React.Fragment>
  );
}
