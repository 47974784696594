// ReactおよびReact関連ライブラリ
import { useState, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";

// Material-UI関連ライブラリ
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Badge, IconButton, Typography, Grid2, Stack } from "@mui/material";

// サードパーティライブラリ
import { FiChevronRight } from "react-icons/fi";
import { isMobile } from "react-device-detect";
import _ from "lodash";

// 画像・アセット
import appLogo from "@/assets/images/appLogo.png";
import notificationIcon from "@/assets/images/notificationIcon.svg";
import cartIcon from "@/assets/images/cartIcon.svg";
import menuIcon from "@/assets/images/menuIcon.svg";
import favIcon from "@/assets/images/favIcon.svg";

// スタイル・カスタムコンポーネント
import { PopoverCustomized } from "@/views/PhaseBStyles/style";

// API・ユーティリティ関連
import { noticeReadCountApiCall } from "@/views/Notice/ApiService";
import { getCookie } from "@/utils/cookieUtils";

// ローカルフック
import { useLocationChange } from "../routerhook";

const TYPE_OF_SEARCH = "components/constants/TYPE_OF_SEARCH";

export default function PrimarySearchAppBar() {
  const isLoggedIn = !!getCookie("access_token");

  const theme = useTheme();
  const iconSize = useMediaQuery(theme.breakpoints.down("sm")) ? "small" : "medium";

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [count, setCount] = useState();
  const [anchorEl, setAnchorEl] = useState(false);

  const makerSelectNav = () => {
    navigate("/search/maker");
    dispatch({
      type: TYPE_OF_SEARCH,
      payload: false,
    });
  };

  const partsSelectNav = () => {
    navigate("/search/type");
    dispatch({
      type: TYPE_OF_SEARCH,
      payload: true,
    });
  };

  const favNavLink = () => {
    navigate("/favorite");
  };

  const noticeNavLink = () => {
    navigate("/notice");
  };

  const cartNavLink = () => {
    if (isLoggedIn) {
      navigate("/cart");
      return;
    }

    navigate("/cart/no-login-empty");
  };

  const HandleAppLogo = () => {
    navigate("/");
  };

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(false);
  };

  const isSimpleHeaderPage =
    window.location.href.includes("auth") &&
    !window.location.href.includes("/auth/signup_complete");

  const id = open ? "simple-popover" : undefined;

  const getApiCount = async () => {
    const { response } = await noticeReadCountApiCall();

    if (response?.data.result == true) {
      setCount(response.data.unread_count);
    }
  };

  //loation change and rendering Navbar
  useLocationChange((location) => {
    // 通知数取得APIを呼び出す処理
    if (
      //  現在のURLがauth(ログインや新規登録)の場合は通知数取得APIは呼び出さない
      //　ただし、 "/auth/signup_complete" (会員登録登録完了画面)では呼び出す。
      isLoggedIn &&
      (!location.pathname.includes("auth") || location.pathname === "/auth/signup_complete")
    ) {
      getApiCount();
    }
  });

  return (
    <Grid2
      container
      sx={{
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgb(39, 39, 39)",
        height: "72px",
        "@media (max-width: 351px)": {
          height: "90px",
        },
        "@media (max-width: 292px)": {
          height: "105px",
        },
      }}
      className="fixedHeader"
    >
      <Grid2 container size={{ xs: 11.5, md: 11, lg: 10 }} height={"100%"} maxWidth={"1500px"}>
        {isSimpleHeaderPage ? (
          <Grid2
            item
            className="logo"
            onClick={HandleAppLogo}
            px={1}
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={appLogo}
              alt="menuLogo"
              style={{ width: "70px", height: "32px", cursor: "pointer" }}
              data-cy="navBarLogo"
            />
          </Grid2>
        ) : (
          <>
            <Grid2 container size={{ xs: 5.4, md: 7.8 }}>
              <IconButton
                size={iconSize}
                color="inherit"
                sx={{ display: { xs: "block", md: "none" } }}
                disableRipple
                onClick={handleClick}
              >
                <img src={menuIcon} alt="menu icon" className="notification-bell opacity-60" />
                <Typography className="font-size-10 opacity-60" sx={{ whiteSpace: "nowrap" }}>
                  メニュー
                </Typography>
              </IconButton>

              <Grid2
                item
                className="logo"
                onClick={HandleAppLogo}
                px={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={appLogo}
                  alt="menuLogo"
                  style={{ width: "70px", height: "32px", cursor: "pointer" }}
                  data-cy="navBarLogo"
                />
              </Grid2>

              <Grid2 container>
                <Typography
                  className={`font-size-14 opacity-80 cursor p-4 ${
                    window.location.pathname != "/search/type" && "headerLink"
                  }`}
                  sx={{ whiteSpace: "nowrap", display: { xs: "none", md: "block" } }}
                  onClick={() => {
                    window.location.pathname !== "/search/type" && partsSelectNav();
                  }}
                >
                  車体番号・類別型式から探す
                </Typography>
              </Grid2>
              {/* 仕切り */}

              <Grid2
                sx={{
                  display: { xs: "none", md: "block" },
                  position: "relative",
                  "::after": {
                    content: '""',
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "2px",
                    height: "32px",
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  },
                }}
              />

              <Grid2 container>
                <Typography
                  className={`font-size-14 opacity-80 cursor p-4 ${
                    window.location.pathname != "/search/maker" && "headerLink"
                  }`}
                  sx={{ whiteSpace: "nowrap", display: { xs: "none", md: "block" } }}
                  onClick={() => {
                    window.location.pathname != "/search/maker" && makerSelectNav();
                  }}
                >
                  メーカーから探す
                </Typography>
              </Grid2>
            </Grid2>

            <Grid2
              container
              size={{ xs: 6.6, md: 4.2 }}
              sx={{
                justifyContent: "flex-end",
              }}
            >
              {!isLoggedIn && (
                <Grid2 sx={{ display: { xs: "none", md: "flex" } }}>
                  <Typography
                    className={`font-size-14 opacity-80 cursor ${
                      window.location.pathname != "/search/maker" && "headerLink"
                    }`}
                    sx={{ whiteSpace: "nowrap" }}
                    p={3}
                    onClick={() => {
                      window.location.href = "/auth/login";
                    }}
                  >
                    ログイン・新規登録
                  </Typography>
                </Grid2>
              )}

              <Grid2 className="cursor headerLink">
                <IconButton
                  size={iconSize}
                  aria-label="show 17 new notifications"
                  color="inherit"
                  disableRipple
                  sx={{ display: { xs: "block", height: "100%" } }}
                  onClick={() => {
                    noticeNavLink();
                  }}
                >
                  <Badge badgeContent={count} color="error">
                    <img
                      alt="notification Icon"
                      src={notificationIcon}
                      className="notification-bell opacity-60"
                    />
                  </Badge>
                  <Typography className="font-size-10 opacity-60" sx={{ whiteSpace: "nowrap" }}>
                    お知らせ
                  </Typography>
                </IconButton>
              </Grid2>

              {isLoggedIn && (
                <>
                  <Grid2 className="cursor headerLink">
                    <IconButton
                      size={iconSize}
                      aria-label="show 17 new notifications"
                      disableRipple
                      color="inherit"
                      onClick={() => {
                        favNavLink();
                      }}
                      sx={{ display: { xs: "block", height: "100%" } }}
                    >
                      <div>
                        <img src={favIcon} alt="favIcon" className="notification-bell opacity-60" />
                      </div>
                      <Typography className="font-size-10 opacity-60" sx={{ whiteSpace: "nowrap" }}>
                        お気に入り
                      </Typography>
                    </IconButton>
                  </Grid2>

                  <Grid2 className="cursor headerLink">
                    <IconButton
                      size={iconSize}
                      aria-label="show 4 new mails"
                      disableRipple
                      color="inherit"
                      onClick={() => {
                        cartNavLink();
                      }}
                      sx={{ display: { xs: "block", height: "100%" } }}
                    >
                      <div>
                        <img
                          src={cartIcon}
                          alt="cartIcon"
                          className="notification-bell opacity-60"
                        />
                      </div>
                      <Typography className="font-size-10 opacity-60" sx={{ whiteSpace: "nowrap" }}>
                        カート
                      </Typography>
                    </IconButton>
                  </Grid2>
                </>
              )}

              <Grid2 className="cursor headerLink">
                <IconButton
                  size={iconSize}
                  aria-label="show 4 new mails"
                  className="header-menu-web-icon"
                  disableRipple
                  color="inherit"
                  sx={{ display: { xs: "none", md: "block", height: "100%" } }}
                  aria-describedby={id}
                  onClick={handleClick}
                >
                  <div>
                    <img src={menuIcon} alt="menuIcon" className="notification-bell opacity-60" />
                  </div>
                  <Typography className="font-size-10 opacity-60" sx={{ whiteSpace: "nowrap" }}>
                    メニュー
                  </Typography>
                </IconButton>
              </Grid2>
            </Grid2>

            <PopoverMenu
              id={id}
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              isLoggedIn={isLoggedIn}
            />
          </>
        )}
      </Grid2>
    </Grid2>
  );
}

function PopoverMenu({ id, open, anchorEl, handleClose, isLoggedIn }) {
  const theme = useTheme();
  const requestPartsLink = isLoggedIn ? "/request" : "/auth/login";

  const matches = useMediaQuery(theme.breakpoints.only("xs"));
  const popupLinks = [
    {
      name: "コーションプレートから探す",
      displayInDesktop: isMobile,
      link: "/search/caution_plate",
    },
    {
      name: "車検証(QR)から探す",
      displayInDesktop: isMobile,
      link: "/search/qr",
    },
    {
      name: "車体番号・類別型式から探す",
      displayInDesktop: true,
      link: "/search/type",
    },
    {
      name: "メーカーから探す",
      displayInDesktop: true,
      link: "/search/maker",
    },
    {
      name: "部品を問い合わせる",
      displayInDesktop: true,
      link: requestPartsLink,
    },
    {
      name: "マイページTOP",
      displayInDesktop: isLoggedIn,
      link: matches ? "/mypage/spTop" : "/mypage",
    },
    {
      name: "購入履歴",
      displayInDesktop: isLoggedIn,
      link: "/mypage/history",
    },
    {
      name: "クレジットカード情報",
      displayInDesktop: isLoggedIn,
      link: "/mypage/card",
    },
    {
      name: "よくある質問",
      displayInDesktop: true,
      link: "/faq",
    },
    {
      name: "UZoneについて",
      displayInDesktop: true,
      link: "/mypage/about",
    },
  ];

  const firstMenu = popupLinks.slice(0, 5);
  const secondMenu = popupLinks.slice(5);
  const popoverMenuPcHeight = useMemo(() => (isLoggedIn ? "600px" : "450px"), [isLoggedIn]);
  const popoverMenuMobileHeight = useMemo(() => (isLoggedIn ? "630px" : "490px"), [isLoggedIn]);

  return (
    <>
      {/* Popup item */}
      <PopoverCustomized
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          className: "navbar-popover-width popover-scroll",
          sx: { height: isMobile ? popoverMenuMobileHeight : popoverMenuPcHeight },
        }}
      >
        <Grid2 container className="navbar-responsive-padding">
          <Grid2 size={{ xs: 12, mb: 2 }}>
            <Typography className="font-size-18-16-variable font-weight-700">商品を探す</Typography>
          </Grid2>
          <Grid2 size={12}>
            <Grid2 container>
              {firstMenu &&
                firstMenu.map((item, index) => {
                  return (
                    item.displayInDesktop && (
                      <Grid2 size={12} key={item.name}>
                        <Link
                          onClick={handleClose}
                          to={{ pathname: item?.link, state: { prevPath: location.pathname } }}
                          style={{ textDecoration: "none" }}
                          className="font-size-16-14-variable font-color-white "
                        >
                          <Stack
                            direction="row"
                            className=" sidebar-menu-hover border-bottom-sidebar-links"
                            sx={{ padding: "12px 0px" }}
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <div className="">{item.name}</div>
                            <div>
                              <FiChevronRight />
                            </div>
                          </Stack>
                        </Link>
                      </Grid2>
                    )
                  );
                })}
            </Grid2>
          </Grid2>

          {/* second menu start */}
          <Grid2 size={12} mb={2} mt={5}>
            <Typography className="font-size-18-16-variable font-weight-700">マイページ</Typography>
          </Grid2>
          <Grid2 size={12}>
            <Grid2 container>
              {secondMenu &&
                secondMenu.map((item, index) => {
                  return (
                    item.displayInDesktop && (
                      <Grid2 size={12} key={`${item.name}-${index}`}>
                        <Link
                          onClick={handleClose}
                          to={item?.link}
                          style={{ textDecoration: "none" }}
                          className="font-size-16-14-variable font-color-white"
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            className=" sidebar-menu-hover border-bottom-sidebar-links"
                            sx={{ padding: "12px 0px" }}
                          >
                            <div className="">{item.name}</div>
                            <div>
                              <FiChevronRight />
                            </div>
                          </Stack>
                        </Link>
                      </Grid2>
                    )
                  );
                })}
            </Grid2>
          </Grid2>
        </Grid2>
      </PopoverCustomized>
    </>
  );
}
