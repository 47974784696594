import React, { useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import OrderImage from "@/components/OrderImage";
import "./checkout.css";
import { QuestionMarkIconApp } from "../PhaseBStyles/style";
import CartDeliveryDialog from "../Cart/CartDeliveryDialog";
import { useSelector, useDispatch } from "react-redux";
import { getPostage } from "../Cart/Cart/action";
import { useEffect } from "react";

export default function CheckoutProduct(props) {
  const dispatch = useDispatch();
  const [cart, setCart] = useState(props.cartProducts);
  const [open, setOpen] = useState(false);
  const title = cart.item_title ?? "　";

  const getPartsMatching = (key) => {
    switch (key) {
      case 0:
        return "適合未確認";
      case 1:
        return "適合";
      case 2:
        return "一部適合";
      case 3:
        return "互換品";
      case 9:
        return;
      default:
        break;
    }
  };

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  const numberWithCommas = (x) => {
    return x && x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const itemPostageData = useSelector((state) => {
    return state.cartReducer.postageList;
  });

  function itemPostageList(itemId) {
    dispatch(
      getPostage({
        payload: { item_id: itemId },
      }),
    );

    setOpen(true);
  }

  useEffect(() => {
    setCart(props.cartProducts);
  }, [props.cartProducts.shipping_rates]);

  return (
    <>
      <Grid container rowSpacing={1} mt={2}>
        <Grid item xs={4} md={3} mr={2}>
          <OrderImage imgPath={cart.path_to_photo_storage ?? ""} productId={cart.item_id} />
        </Grid>
        <Grid item xs={7} md={8}>
          <Typography
            component="p"
            variant="p"
            sx={{ marginTop: { xs: "2px", md: "5px" } }}
            className="font-size-18"
          >
            {title}
          </Typography>
          {cart.custom_field_label && (
            <Typography component="p" variant="p" className="font-size-14 opacity-80" mt={"4px"}>
              {cart.custom_field_label} :{" "}
              {cart.custom_field_value === null ? "-" : cart.custom_field_value}
            </Typography>
          )}
          {cart.item_condition && (
            <Typography component="p" variant="p" className="font-size-14 opacity-80" mt={"4px"}>
              品質ランク : {cart.item_condition}
            </Typography>
          )}
          <Typography component="p" variant="p" className="font-size-14 opacity-80" mt={"4px"}>
            出品者 : {"株式会社パーツワン"}
          </Typography>
          {cart.quantity_details && (
            <Typography component="p" variant="p" className="font-size-14 opacity-80" mt={"4px"}>
              部品点数： {cart.quantity_details}
            </Typography>
          )}

          <Grid container sx={{ mt: 1 }}>
            {getPartsMatching(cart.parts_matching) && (
              <Grid item xs={12} md={6}>
                <Stack direction="row" spacing={2}>
                  <Typography
                    component="span"
                    variant="span"
                    mt={"10px"}
                    className="badge-red"
                    data-cy="partsMatching"
                  >
                    {getPartsMatching(cart.parts_matching)}
                  </Typography>
                </Stack>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <Stack alignItems="flex-end" mt={1}>
                <div>
                  <span className="font-size-14 opacity-80">本体価格 </span>
                  <span className="font-size-24 font-weight-700">
                    {numberWithCommas(cart.sales_price)}
                  </span>
                  <span className="font-size-16 font-weight-700">{" 円"}</span>
                  <span className="font-size-12 opacity-80">{" (税抜)"}</span>
                </div>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={1}
                  onClick={() => itemPostageList(cart.item_id)}
                  data-cy={`postageList${cart.item_id}`}
                >
                  <QuestionMarkIconApp />
                  <div>
                    <span className="font-size-14 opacity-80">送料 </span>
                    <span className="font-size-18 opacity-80">
                      {numberWithCommas(cart.shipping_rates)}
                    </span>
                    <span className="font-size-14 opacity-80"> 円 (税抜)</span>
                  </div>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CartDeliveryDialog
        open={open}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        list={itemPostageData}
      />
    </>
  );
}
